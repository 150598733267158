<template>
  <pipeline-body ref="body" @empty="emptyHandler" />
</template>

<script>
import PageMixin from '../mixins/Page'
import PipelineBody from '../bodies/Pipeline.vue'
import UserMeta from '../mixins/UserMeta'

export default {
  mixins: [PageMixin, UserMeta],
  name: 'PiplinePage',
  data() {
    return {
      vis: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    emptyHandler() {}
  },
  components: {
    PipelineBody
  },
  props: {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
